import { createRouter, createWebHashHistory } from 'vue-router'
import { getStorage } from '@/utils/storage'

const routes = [
  {
    path: '/',
    redirect: "/layout"
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/Login/index.vue")
  },
  {
    path: "/layout",
    name: "layout",
    meta: {
      title: "首页"
    },
    component: () => import("@/views/Layout/index.vue"),
    redirect: "/layout/home",
    children: [
      {
        path: "home",
        name: "home",
        meta: {
          title: "首页"
        },
        component: () => import("@/views/Home/index.vue")
      },
      {
        path: "partners",
        name: "partners",
        meta: {
          title: "合伙人"
        },
        component: () => import("@/views/Partners/index.vue")
      }
    ]
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "404页面"
    },
    component: () => import("@/views/404Page/index.vue")
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.path == "/login" || to.path == "/404") {
    next()
  } else {
    if (!getStorage("userInfo")) {
      router.push("/login")
    } else {
      next()
    }
  }
})

export default router
