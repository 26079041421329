<template>
  <router-view></router-view>
</template>

<script setup>

</script>

<style lang="less">
@main-color: #F59A23;

body {
  margin: 0;
  padding: 0;
}

// // 滚动条样式
::-webkit-scrollbar {
  width: 0px;
}

.el-input__wrapper.is-focus {
  box-shadow: 0 0 0 1px #F59A23 !important;

  .el-input__inner {
    caret-color: #F59A23;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

// 分页器样式
.el-pagination.is-background .el-pager li.is-active {
  color: #fff !important;
  background-color: @main-color !important;
}

.el-pagination.is-background .el-pager li {
  color: @main-color !important;
}
</style>
