import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from "@/store/index"
import ElementPlus from "element-plus"
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App)

app.use(pinia)
    .use(router)
    .use(ElementPlus,{
        locale: zhCn
    })
    .mount('#app')
